import React from 'react';

import * as Icon from "react-feather";
import Fade from "react-reveal/Fade";

import "./scrolltop.scss";

const ScrollTop = () => {
    const [show, SetShow] = React.useState(true);

    React.useEffect(() => {
        if (typeof window !== "undefined") {
            window.onscroll = () => {
              if (window.pageYOffset > 2460) {
                SetShow(false);
              } else {
                SetShow(true);
              }
            }
        }
    }, []);

    return (
        <div className={show ? 'scrollToTop d-none' : 'scrollToTop'}>
            <Fade up>
                <button 
                    aria-label="Scroll-to-top"
                    type="button" 
                    onClick={() => window.scrollTo(0, 0)} 
                    children={<Icon.ChevronUp size={26} />}
                />
            </Fade>
        </div>
    );
};

export default ScrollTop;