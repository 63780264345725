import React from 'react';

import Fade from "react-reveal/Fade";

const PostForm = () => (
    <div className="PostForm text-left" style={{ maxWidth: '720px', margin: '0 auto', marginTop: '80px' }}>
        <form method="post" action="https://getform.io/f/02314aa4-15e8-40ce-8bb9-3e940ea89c5a">
            <div class="form-row">
                <div class="col-12 col-lg-6">
                    <label>First- & lastname *</label>
                    <input type="text" name="name" className="form-control" />
                </div>
                
                <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                    <label>Company</label>
                    <input type="text" name="company" className="form-control" />
                </div>
            </div>

            <div class="form-row mt-4">
                <div class="col-12 col-lg-6">
                    <label>E-mail *</label>
                    <input type="text" name="email" className="form-control" />
                </div>

                <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                    <label>Phonenumber</label>
                    <input type="text" name="phone" className="form-control" />
                </div>
            </div>

            <div class="form-row mt-4">
                <div class="col">
                    <label>Message</label>
                    <textarea name="message" placeholder="ex. information about the website/mobileapp, if you have a website today etc.," className="form-control" />
                </div>
            </div>

            <div class="form-row text-right">
                <div class="col">
                    <div className="button-group">
                        <button 
                            type="submit" 
                            className="btn btn-primary" 
                            children="Work with us" 
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
)

const ContactForm = ({ text, buttons }) => (
    <section className="header no-height color">
        <div className="container-fluid">
            <div className="row">
                <div className="col text-center">
                    {text !== undefined && (
                        <div className="text">
                            {text.h1 !== undefined && (
                                <Fade down>
                                    <h1>{text.h1}</h1>
                                </Fade>
                            )}
                            {text.h2 !== undefined && (
                                <Fade down>
                                    <h2>{text.h2}</h2>
                                </Fade>
                            )}
                            {text.h3 !== undefined && (
                                <Fade down>
                                    <h3>{text.h3}</h3>
                                </Fade>
                            )}
                        </div>
                    )}
                    <PostForm />
                </div>
            </div>
        </div>
    </section>
)

export default ContactForm;