import React, { useState } from 'react';

import { Link } from "gatsby";

import Fade from "react-reveal/Fade";

import * as Icon from "react-feather";
import "./top.scss";

const Top = ({ contactinfo }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <Fade down>
            <section className="top">
                <div className={menuOpen ? 'menu' : 'd-none'}>
                    <ul className="list-unstyled">
                        {contactinfo.phone !== undefined && (
                            <li>
                                <a href={`callto:${contactinfo.phone.replace(/\s+/g, '')}`}>{contactinfo.phone}</a>
                            </li>
                        )}
                        {contactinfo.email !== undefined && (
                            <li>
                                <a href={`mailto:${contactinfo.email}`}>{contactinfo.email}</a>
                            </li>
                        )}
                    </ul>
                </div>

                <div className="logotype">
                    <Link to="/">
                        <img src="/design/logotyp.svg" alt="Leads Group" />
                    </Link>
                </div>

                <div className="mobile-menu">
                    <div className="icon-holder" aria-label="button" onClick={() => setMenuOpen(menuOpen ? false : true)}>
                        {menuOpen ? <Icon.X /> : <Icon.Menu />}
                    </div>
                </div>
                
                <div className="contact-info">
                    <ul className="list-inline">
                        {contactinfo.phone !== undefined && (
                            <li className="list-inline-item">
                                <a href={`callto:${contactinfo.phone.replace(/\s+/g, '')}`}>{contactinfo.phone}</a>
                            </li>
                        )}
                        {contactinfo.email !== undefined && (
                            <li className="list-inline-item">
                                <a href={`mailto:${contactinfo.email}`}>{contactinfo.email}</a>
                            </li>
                        )}
                    </ul>
                </div>
            </section>
        </Fade>
    );
};

export default Top;