import React from "react";

import { Link } from "gatsby";

import Slider from "react-slick";
import Fade from "react-reveal/Fade";

import * as Icon from "react-feather";

import "./servicecards.scss";

const ServiceCards = ({ contactRef }) => {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 3,
        initialSlide: 0,
        rows: 2,
        slidesPerRow: 1,
        slidesToShow: 3,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    rows: 1,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    rows: 1,
                    slidesPerRow: 1,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    rows: 1,
                    slidesToShow: 1.1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <section className="servicecards">
            <div className="container container-fluid-lg">
                <div className="row">
                    <div className="col">
                        <Fade left>
                            <h2>It’s not what you say, it’s what you do.</h2>
                            <h4>End-to-end services that guide you fearlessly into the future.</h4>
                        </Fade>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Fade up>
                            <div className="slider-holder">
                                <button 
                                    aria-label="Next"
                                    style={{ border: 0 }}
                                    className="next-arrow" 
                                    onClick={() => console.log(``)} 
                                    children={<Icon.ChevronRight size={28} />}
                                />
                                <Slider {...settings}>
                                    <div className="service-holder">
                                        <div className="service">
                                            <div className="text">
                                                <img src="/icons/card-icons/design.png" alt="" />
                                                <h2>Designing your digital product</h2>
                                                <p>From product design to strategy, design systems, UX or UI. I solve complex problems through design, so you can achieve your business goals.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="service-holder">
                                        <div className="service">
                                            <div className="text">
                                                <img src="/icons/card-icons/shopify.png" alt="" />
                                                <h2>Designing  a Shopify store</h2>
                                                <p>One of my favourites. I can help you design your custom ecommerce store and make sure it is optimised so you can improve your conversion rate.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="service-holder">
                                        <div className="service">
                                            <div className="text">
                                                <img src="/icons/card-icons/development.png" alt="" />
                                                <h2>Building a custom responsive site</h2>
                                                <p>I can build custom responsive site's with wordpress, prismic or contentful in the back-end.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="service-holder">
                                        <div className="service">
                                            <div className="text">
                                                <img src="/icons/card-icons/branding.png" alt="" />
                                                <h2>Giving a boost to your brand</h2>
                                                <p>Do you have a site or digital product that lacks personality? Let me add some value to your product with a branding boost that you’ll love.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="service-holder">
                                        <div className="service">
                                            <div className="text">
                                                <img src="/icons/card-icons/apps.png" alt="" />
                                                <h2>Designing a native mobile app</h2>
                                                <p>Designing an app is completely different from designing a site. I can help you develop your native app optimised for all devices, platforms and ecosystems.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="service-holder">
                                        <div className="service blue">
                                            <div className="text">
                                                <h2>That’s it! Now it’s your turn to say hi.</h2>
                                                <p>Do you need help with your next project?</p>
                                                <span aria-label="button" onClick={() => contactRef && contactRef.current.scrollIntoView()}>Contact us</span>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceCards
