import React, { useState } from "react";

import Fade from "react-reveal/Fade";

import "./caselist.scss";

const CaseList = ({ cases, text }) => {
    const [limit, setLimit] = useState(3);

    return (
        <section className="caselist">
            <div className="container">
                {text !== undefined && (
                <div className="row mb-5">
                    <div className="col">
                        <div className="text">
                            <Fade left>
                                {text.h2 !== undefined && (
                                    <h2>{text.h2}</h2>
                                )}
                                {text.h3 !== undefined && (
                                    <h3>{text.h3}</h3>
                                )}
                            </Fade>
                        </div>
                    </div>
                </div>
                )}

                {cases.slice(0, limit).map((image, key) => 
                <div className="row mt-5" key={key}>
                    <Fade up>
                        <div className="col-lg-12">
                            <div className="image-holder">
                                <picture>
                                    <source media="(max-width:768px)" srcSet={`/cases/${image.mobile}`} />
                                    <img src={`/cases/${image.desktop}`} className="img-fluid" alt="" />
                                </picture>
                            </div>
                        </div>
                    </Fade>
                </div>
                )}
                
                {cases.length > limit && (
                    <div className="row mt-5">
                        <div className="col text-center">
                            <button type="button" className="btn btn-light" children="Show more cases" onClick={() => setLimit(limit+3)} />
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

export default CaseList