import React, { useRef } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ServiceCards from "../components/servicecards";
import Top from "../components/top";
import Header from "../components/header";
import ImageGrid from "../components/imagegrid";
import ServiceLogos from "../components/servicelogos";
import CaseList from "../components/caselist";
import Footer from "../components/footer";
import ScrollTop from "../components/scrolltop";
import FloatingTexts from "../components/floatingtext";

import * as Icon from "react-feather";

// data
import caselist from '../data/caselist.json';
import ContactForm from "../components/contactform";
import Jumbotron from "../components/jumbotron";

const IndexPage = () => {
  const workRef = useRef(null);
  const contactRef = useRef(null);
  
  return (
    <Layout>
      <SEO 
        title="Leads Group" 
        description="I help agencies & brands like Lemond, Google and others to turn their ideas into designs. Great designs and conversion."
      />
      <ScrollTop />
      
      <Top 
        contactinfo={{ 
          email: 'hi@leadsgroup.se',
          phone: ''
        }} 
      />
      <Header 
        size="big" 
        text={{ 
          h1: 'Freelance Designer',
          h3: 'I help agencies & brands like Lemond, Google and others to turn their ideas into designs. Great designs and conversion.',
        }} 
        buttons={[
          <a 
            href="mailto:hi@leadsgroup.se" 
            aria-label="button" 
            className="btn btn-primary d-inline-flex align-items-center" 
            children={<><Icon.Mail className="mr-2" />hi@leadsgroup.se</>} 
          />,
          <button 
            type="button" 
            className="btn d-inline-flex align-items-center" 
            onClick={() => workRef && workRef.current.scrollIntoView()} 
            children={<><Icon.ChevronDown className="mr-2" /> See more work</>} 
          />
        ]} 
      />
      <ImageGrid />
      <ServiceLogos />
      <Jumbotron />
      <ServiceCards contactRef={contactRef} />
      <div ref={workRef}>
        <CaseList 
        text={{ 
          h2: 'We are helping companies to develop & branding their products, services & brands.',
          h3: 'Long term engagement, branding, strategi, communication, concept and design - on all social platforms.'
         }}
          cases={caselist} 
        />
      </div>
      <FloatingTexts />
      <div ref={contactRef}>
        <ContactForm 
          text={{ 
            h2: 'Let\'s work together',
            h3: <span className="option-2">Please fill out the form bellow and we will contact you in 24hours to discuss your new project.</span>
          }} 
        />
      </div>
      <Footer />
    </Layout>
  )
}

export default IndexPage;
