import React from 'react';

import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import Fade from "react-reveal/Fade";
import "./imagegrid.scss";

const ImageGrid = () => {
    const data = useStaticQuery(
        graphql`
            query {
                first: file(relativePath: { eq: "content/computer-chilling.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 1340, maxHeight: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                },
                second: file(relativePath: { eq: "content/work-desk.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 1340, maxHeight: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                },
                third: file(relativePath: { eq: "content/computer-travelling.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 1340, maxHeight: 1200, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    )

    return (
        <Fade up>
            <section className="imagegrid">
                <div className="image">
                    <Img fluid={data.first.childImageSharp.fluid} width="100%" height="auto" alt="" />
                </div>
                <div className="image">
                    <Img fluid={data.second.childImageSharp.fluid} width="100%" height="auto" alt="" />
                </div>
                <div className="image">
                    <Img fluid={data.third.childImageSharp.fluid} width="100%" height="auto" alt="" />
                </div>
            </section>
        </Fade>
    );
};

export default ImageGrid;