import React from 'react';

import Fade from "react-reveal/Fade";

import "./servicelogos.scss";

const ServiceLogos = () => {
    return (
        <section className="service-logos">
            <div className="container-fluid">
                <div className="row">
                <div className="col text-center">
                    <Fade up>
                        <img src="logos/platform.png" className="img-fluid" alt="" />
                    </Fade>
                </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceLogos;
