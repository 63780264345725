import React from 'react';

import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import "./header.scss"

const Header = ({ size, text, buttons, height }) => {

    if (size === 'big') {
        return (
            <section className={height !== undefined && height === false ? 'header no-height color' : 'header'}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col text-center">
                            {text !== undefined && (
                                <div className="text">
                                    {text.h1 !== undefined && (
                                        <Fade down>
                                            <h1>{text.h1}</h1>
                                        </Fade>
                                    )}
                                    {text.h2 !== undefined && (
                                        <Fade down>
                                            <h2>{text.h2}</h2>
                                        </Fade>
                                    )}
                                    {text.h3 !== undefined && (
                                        <Slide down>
                                            <h3>{text.h3}</h3>
                                        </Slide>
                                    )}
                                </div>
                            )}
                            {buttons !== undefined && (
                                <Fade up>
                                    <div className="button-group">
                                        {buttons.map((button, index) => button)}
                                    </div>
                                </Fade>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="header small">
            <div className="container">
                <div className="row">
                    <div className="col">
                        {text !== undefined && (
                            <div className="text">
                                {text.h2 !== undefined && (
                                    <Fade left>
                                        <h2>{text.h2}</h2>
                                    </Fade>
                                )}
                                {text.h4 !== undefined && (
                                    <Fade left>
                                        <h2>{text.h4}</h2>
                                    </Fade>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;
