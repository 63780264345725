import React from 'react';

import Fade from "react-reveal/Fade";
import * as Icon from "react-feather";

import "./footer.scss";

const Footer = ({ type }) => {

    if (type === 1) {
        return (
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-lg-3">
                            <Fade up>
                                <h4>About us</h4>
                                <ul className="list-unstyled">
                                    <li>Who are we?</li>
                                    <li>Our team</li>
                                    <li>Career</li>
                                </ul>
                            </Fade>
                        </div>
                        <div className="col-6 col-lg-3">
                            <Fade up>
                                <h4>Our services</h4>
                                <ul className="list-unstyled">
                                    <li>Website & Mobile apps</li>
                                    <li>Marketing</li>
                                    <li>UX/UI design</li>
                                </ul>
                            </Fade>
                        </div>
                        <div className="col-6 col-lg-3 mt-5 mt-lg-0">
                            <Fade up>
                                <h4>Contact us</h4>
                                <ul className="list-unstyled">
                                    <li>Contact us</li>
                                    <li>Headquaters</li>
                                    <li>Offices</li>
                                </ul>
                            </Fade>
                        </div>
                        <div className="col-6 col-lg-3 mt-5 mt-lg-0">
                            <Fade up>
                                <h4>Follow us</h4>
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <Icon.Facebook fill />
                                    </li>
                                    <li className="list-inline-item ml-1">
                                        <Icon.Instagram />
                                    </li>
                                    <li className="list-inline-item ml-1">
                                        <Icon.Linkedin fill />
                                    </li>
                                    <li className="list-inline-item ml-1">
                                        <Icon.Twitter fill />
                                    </li>
                                </ul>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className="footer small">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4 offset-lg-4 text-center">
                        Copyright © Leads Group {new Date().getFullYear()}
                    </div>

                    <div className="col-12 col-lg-4 mt-5 mt-lg-0 text-center text-lg-right">
                        <Icon.Facebook fill />
                        <Icon.Instagram className="ml-2" />
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Footer;
