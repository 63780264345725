import React from 'react';

import "./floatingtext.scss";

const FloatingTexts = () => {
    return (
    <>
        <section className="floatingtexts">
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
            <div className="text">Strategy</div>
        </section>
        <section className="floatingtexts">
            <div className="text">Design</div>
            <div className="text">Design</div>
            <div className="text">Design</div>
            <div className="text">Design</div>
            <div className="text">Design</div>
            <div className="text">Design</div>
            <div className="text">Design</div>
            <div className="text">Design</div>
            <div className="text">Design</div>
            <div className="text">Design</div>
        </section>
        <section className="floatingtexts">
            <div className="text">Development</div>
            <div className="text">Development</div>
            <div className="text">Development</div>
            <div className="text">Development</div>
            <div className="text">Development</div>
            <div className="text">Development</div>
            <div className="text">Development</div>
            <div className="text">Development</div>
            <div className="text">Development</div>
            <div className="text">Development</div>
        </section>
        <section className="floatingtexts">
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
            <div className="text">Marketing</div>
        </section>
    </>
    )
}

export default FloatingTexts;