import React from 'react';

import Fade from "react-reveal/Fade";

import './jumbotron.scss';

const Jumbotron = () => {
    return (
        <section className="jumbotron">
            <div className="container">
                <div className="row">
                    <div className="col-12 offset-xl-2 col-xl-8">
                        <Fade left>
                            <h2>Your website should turn visitors into engaged customers.</h2>
                            <h3>It’s like having a top-performing sales rep working 24 hours a day, 7 days a week.</h3>
                        </Fade>
                        
                        <Fade up>
                            <p>In today’s busy world, your customers are constantly bombarded with different marketing messages, which makes it significantly more difficult for customers to focus and make right choice. In order to stand out from the noise, your signal should be clear and precise.</p>
                        </Fade>

                        <Fade up>
                            <p>From Pre-Seed to Series A, I help startups create relationship with their customers, by communicating their functional message, personality and values to prospective customer, trough clear, comprehensible design and memorable customer experience.</p>
                        </Fade>

                        <Fade left>
                            <div className="w-100 text-right">
                                <a href="#0" className="btn btn-primary">Book a free consultation</a>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Jumbotron;